import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    redirect: "/Index"
  },
  {
    path: '/Index',
    name: 'Index',
    component: () => import('../views/Index/Index.vue'),
  },
  {
    path: '/Page',
    name: 'Page',
    component: () => import('../views/Page/Page.vue'),
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay/index.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
